import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createBatchOrder } from '../../util/api';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';

// ================ Action types ================ //
export const BATCHES_REQUEST = 'app/BatchListPage/BATCHES_REQUEST';
export const BATCHES_SUCCESS = 'app/BatchListPage/BATCHES_SUCCESS';
export const BATCHES_ERROR = 'app/BatchListPage/BATCHES_ERROR';

export const ADD_LISTING_TO_BATCH = 'app/BatchListPage/ADD_LISTING_TO_BATCH';
export const REMOVE_LISTING_FROM_BATCH = 'app/BatchListPage/REMOVE_LISTING_FROM_BATCH';

export const CREATE_BATCH_REQUEST = 'app/BatchListPage/CREATE_BATCH_REQUEST';
export const CREATE_BATCH_SUCCESS = 'app/BatchListPage/CREATE_BATCH_SUCCESS';
export const CREATE_BATCH_ERROR = 'app/BatchListPage/CREATE_BATCH_ERROR';
export const CLEAR_BATCH = 'app/BatchListPage/CLEAR_BATCH';
const resultIds = data => {
  return data.data.map(l => l.id);
};
// ================ Reducer ================ //
const initialState = {
  allListingError: null,
  allListingInProgress: false,
  allListingIds: [],
  pagination: null,
  batch: [],
  createBatchError: null,
  createBatchInProgress: false,
};

const BatchListPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case BATCHES_REQUEST:
      return { ...state, allListingInProgress: true, allListingError: null };
    case BATCHES_SUCCESS:
      return {
        ...state,
        allListingInProgress: false,
        allListingIds: resultIds(payload?.data),
        pagination: payload.data.meta,
      };
    case BATCHES_ERROR:
      return { ...state, allListingInProgress: false, allListingError: payload };
    case ADD_LISTING_TO_BATCH:
      return { ...state, batch: [...state.batch, payload] };
    case REMOVE_LISTING_FROM_BATCH:
      return { ...state, batch: state.batch.filter(listing => listing.id !== payload.id) };
    case CREATE_BATCH_REQUEST:
      return { ...state, createBatchInProgress: true, createBatchError: null };
    case CREATE_BATCH_SUCCESS:
      return { ...state, createBatchInProgress: false };
    case CREATE_BATCH_ERROR:
      return { ...state, createBatchInProgress: false, createBatchError: payload };
    case CLEAR_BATCH:
      return { ...state, batch: [] };
    default:
      return state;
  }
};

export default BatchListPageReducer;

// ================ Action creators ================ //
export const batchesRequest = () => ({ type: BATCHES_REQUEST });
export const batchesSuccess = data => ({ type: BATCHES_SUCCESS, payload: data });
export const batchesError = e => ({ type: BATCHES_ERROR, payload: e });

export const addListingToBatch = listing => ({ type: ADD_LISTING_TO_BATCH, payload: listing });
export const removeListingFromBatch = listing => ({
  type: REMOVE_LISTING_FROM_BATCH,
  payload: listing,
});

export const createBatchRequest = () => ({ type: CREATE_BATCH_REQUEST });
export const createBatchSuccess = () => ({ type: CREATE_BATCH_SUCCESS });
export const createBatchError = e => ({ type: CREATE_BATCH_ERROR, payload: e });
// ================ Thunks ================ //
export const createBatchTransaction = (batch, id, discount) => (dispatch, getState, sdk) => {
  dispatch(createBatchRequest());
  return createBatchOrder({ batch, authorId: id, discount })
    .then(res => {
      dispatch(createBatchSuccess());
      return res;
    })
    .catch(e => {
      dispatch(createBatchError(storableError(e)));
    });
};
export const batchListings = (authorId, page = 1) => (dispatch, getState, sdk) => {
  dispatch(batchesRequest());
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  return sdk.listings
    .query({
      authorId: authorId,
      minStock: 1,
      include: ['author', 'images'],
      'fields.image': [
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
        `variants.${variantPrefix}-4x`,
        `variants.${variantPrefix}-6x`,
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
      page,
      // perPage: 25,
      'limit.images': 1,
      pub_holidayMode: 'off',
      pub_deleted: 'no',
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(batchesSuccess(response));
    })
    .catch(e => {
      dispatch(batchesError(storableError(e)));
    });
};
export const loadData = (params, search) => dispatch => {
  const { id: authorId } = params;
  const page = parse(search).page || 1;
  return dispatch(batchListings(authorId, page));
};
