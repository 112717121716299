import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { addAndRemoveSellerToFavourites } from '../../util/api';
import { fetchFavourites } from '../WishlistPage/SellerFavouritePage.duck';
import { parse } from '../../util/urlHelpers';
const { UUID } = sdkTypes;
const RESULT_PAGE_SIZE = 20;
// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const ADD_SELLER_TO_FAVOURITES_REQUEST = 'app/ProfilePage/ADD_SELLER_TO_FAVOURITES_REQUEST';
export const ADD_SELLER_TO_FAVOURITES_SUCCESS = 'app/ProfilePage/ADD_SELLER_TO_FAVOURITES_SUCCESS';
export const ADD_SELLER_TO_FAVOURITES_ERROR = 'app/ProfilePage/ADD_SELLER_TO_FAVOURITES_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  addSellerToFavouritesError: null,
  addSellerToFavouritesInProgress: false,
  favouritesCount: null,
  userLoaded: false,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return {
        ...state,
        userShowError: null,
        userLoaded: payload.loading,

        userId: payload.userId,
      };
    case SHOW_USER_SUCCESS:
      return {
        ...state,
        userLoaded: false,
      };
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload, userLoaded: false };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],

        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs, pagination: payload.meta };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };
    case ADD_SELLER_TO_FAVOURITES_REQUEST:
      return { ...state, addSellerToFavouritesError: null, addSellerToFavouritesInProgress: true };
    case ADD_SELLER_TO_FAVOURITES_SUCCESS:
      return {
        ...state,
        addSellerToFavouritesInProgress: false,
        favouritesCount: {
          ...state.favouritesCount,
          [payload.userId]: payload.count,
        },
      };
    case ADD_SELLER_TO_FAVOURITES_ERROR:
      return {
        ...state,
        addSellerToFavouritesError: payload,
        addSellerToFavouritesInProgress: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = (userId, loading) => ({
  type: SHOW_USER_REQUEST,
  payload: { userId, loading },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = (listingRefs, meta) => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs, meta },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const addSellerToFavouritesRequest = () => ({
  type: ADD_SELLER_TO_FAVOURITES_REQUEST,
});

export const addSellerToFavouritesSuccess = count => ({
  type: ADD_SELLER_TO_FAVOURITES_SUCCESS,
  payload: count,
});

export const addSellerToFavouritesError = e => ({
  type: ADD_SELLER_TO_FAVOURITES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryUserListings = params => (dispatch, getState, sdk) => {
  const { userId, perPage, page } = params;
  dispatch(queryListingsRequest(userId));

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  return sdk.listings
    .query({
      author_id: userId,
      perPage,
      page,
      pub_deleted: 'no',
      minStock: 1,
      pub_holidayMode: 'off',
      include: ['author', 'images'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(listingRefs, response.data.meta));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const showUser = (userId, loading = false) => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId, loading));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage', 'profile', 'profile.protectedData', 'profile.metadata'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};
export const addSellerToFavourites = userId => (dispatch, getState, sdk) => {
  dispatch(addSellerToFavouritesRequest());
  // console.log('userId', userId);
  return addAndRemoveSellerToFavourites({ sellerId: userId })
    .then(response => {
      const count = response?.favouritesCount || 0;

      return Promise.all([
        dispatch(fetchCurrentUser()),
        dispatch(showUser(new UUID(userId), true)),
        // dispatch(queryUserListings(userId)),
        // dispatch(queryUserReviews(userId)),
        dispatch(fetchFavourites()),
        dispatch(
          addSellerToFavouritesSuccess({
            count,
            userId,
          })
        ),
      ]);
      // console.log('response', response);

      return response;
    })
    .catch(e => dispatch(addSellerToFavouritesError(storableError(e))));
};
export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const userId = new UUID(params.id);
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    // dispatch(queryUserListings(userId)),
    dispatch(
      queryUserListings({
        userId,
        page,
        perPage: RESULT_PAGE_SIZE,
      })
    ),
    dispatch(queryUserReviews(userId)),
  ]);
};
