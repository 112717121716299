/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */
import accessories from './Icons/Accessories.png';
import books from './Icons/books.png';
import buttons from './Icons/Buttons.png';
import clothes from './Icons/clothes.png';
import furnishing from './Icons/furnishing.png';
import machine from './Icons/machine.png';
import sewing from './Icons/sewing.png';
import tools from './Icons/tools.png';
import trim from './Icons/Trims.png';
// - Small : 0 kg to 0,5 kg
// - Medium 0,51 kg to 1 kg
// - Large 1,01 kg to 3 kg
// - Extra large 3,01 kg to 5 kg
// - Machine / Dress form 5,01 kg to 15 kg
export const discountOptions = [
  // {
  //   label: '--',
  //   value: '0',
  // },
  {
    label: '5%',
    value: '5',
  },
  {
    label: '10%',
    value: '10',
  },
  {
    label: '15%',
    value: '15',
  },
  {
    label: '20%',
    value: '20',
  },
  {
    label: '25%',
    value: '25',
  },
  {
    label: '30%',
    value: '30',
  },
  {
    label: '35%',
    value: '35',
  },
  {
    label: '40%',
    value: '40',
  },
  {
    label: '45%',
    value: '45',
  },
  {
    label: '50%',
    value: '50',
  },
];
export const shippingMethodsData = [
  {
    label: 'Chronopost',
    key: 'chronopost',
  },
  {
    label: 'Colis Privé ',
    key: 'colisprive',
  },
  {
    label: 'Mondial Relay',
    key: 'mondial_relay',
  },
];
export const packageSize = [
  {
    key: 'small',
    label: 'config.marketplace-custom-config.smallLabel',
    sub_label: 'config.marketplace-custom-config.smallSubLabel',
    sub_text: 'config.marketplace-custom-config.smallSubTextLabel',
  },
  {
    key: 'medium',
    label: 'config.marketplace-custom-config.mediumLabel',
    sub_label: 'config.marketplace-custom-config.mediumSubLabel',
    sub_text: 'config.marketplace-custom-config.mediumSubText',
  },
  {
    key: 'large',
    label: 'config.marketplace-custom-config.largeLabel',
    sub_label: 'config.marketplace-custom-config.largeSubLabel',
    sub_text: 'config.marketplace-custom-config.largeText',
  },
  {
    key: 'extraLarge',
    label: 'config.marketplace-custom-config.extraLabel',
    sub_label: 'config.marketplace-custom-config.extraSubLabel',
    sub_text: 'config.marketplace-custom-config.extraText',
  },
  {
    key: 'machine',
    label: 'config.marketplace-custom-config.machineLabel',
    sub_label: 'config.marketplace-custom-config.machineSublabel',
    sub_text: 'config.marketplace-custom-config.machineText',
  },
];
export const countries = [
  {
    label: 'Belgium',
    key: 'BE',
    value: 'BE',
  },

  {
    label: 'France',
    key: 'FR',
    value: 'FR',
  },
];
export const categories = [
  { label: 'category.fabrics', value: 'fabrics', key: 'fabrics' },
  { label: 'category.notions', value: 'notions', key: 'notions' },
  {
    label: 'category.machinesTools',
    value: 'machinesTools',
    key: 'machinesTools',
  },
];

export const fabricsSubCategory = [
  {
    label: 'subCategory.clothingFabrics',
    value: 'clothingFabrics',
    key: 'clothingFabrics',
    icon: clothes,
  },
  {
    label: 'subCategory.furnishingFabrics',
    value: 'furnishingFabrics',
    key: 'furnishingFabrics',
    icon: furnishing,
  },
];
export const notionsSubCategory = [
  { label: 'subCategory.buttons', value: 'buttons', key: 'buttons', icon: buttons },
  { label: 'subCategory.trims', value: 'trims', key: 'trims', icon: trim },
  { label: 'subCategory.accessories', value: 'accessories', key: 'accessories', icon: accessories },
];
export const machinesToolsSubCategory = [
  { label: 'subCategory.machines', value: 'machines', key: 'machines', icon: machine },
  { label: 'subCategory.tools', value: 'tools', key: 'tools', icon: tools },
  {
    label: 'subCategory.sewingStorage',
    value: 'sewingStorage',
    key: 'sewingStorage',
    icon: sewing,
  },
  {
    label: 'subCategory.booksSewingPatterns',
    value: 'booksSewingPatterns',
    key: 'booksSewingPatterns',
    icon: books,
  },
];
export const subCategories = [
  ...fabricsSubCategory,
  ...notionsSubCategory,
  ...machinesToolsSubCategory,
];
export const clothingFabricsSubSubCategory = [
  { label: 'subSubCategory.cotton_fabric', key: 'cotton_fabric', value: 'cotton_fabric' },
  {
    label: 'subSubCategory.viscose_and_rayon_fabric',
    key: 'viscose_and_rayon_fabric',
    value: 'viscose_and_rayon_fabric',
  },
  { label: 'subSubCategory.gauze_fabric', key: 'gauze_fabric', value: 'gauze_fabric' },
  { label: 'subSubCategory.tencel_fabric', key: 'tencel_fabric', value: 'tencel_fabric' },
  { label: 'subSubCategory.jersey_fabric', key: 'jersey_fabric', value: 'jersey_fabric' },
  { label: 'subSubCategory.ribs_fabric', key: 'ribs_fabric', value: 'ribs_fabric' },
  { label: 'subSubCategory.knit_fabric', key: 'knit_fabric', value: 'knit_fabric' },
  {
    label: 'subSubCategory.sweatshirt_and_jogging_fabric',
    key: 'sweatshirt_and_jogging_fabric',
    value: 'sweatshirt_and_jogging_fabric',
  },
  { label: 'subSubCategory.fleece_fabric', key: 'fleece_fabric', value: 'fleece_fabric' },
  { label: 'subSubCategory.velvet_fabric', key: 'velvet_fabric', value: 'velvet_fabric' },
  { label: 'subSubCategory.linen_fabric', key: 'linen_fabric', value: 'linen_fabric' },
  { label: 'subSubCategory.crepe_fabric', key: 'crepe_fabric', value: 'crepe_fabric' },
  { label: 'subSubCategory.gabardine_fabric', key: 'gabardine_fabric', value: 'gabardine_fabric' },
  { label: 'subSubCategory.flannnel_fabric', key: 'flannnel_fabric', value: 'flannnel_fabric' },
  {
    label: 'subSubCategory.denim_and_chambray_fabric',
    key: 'denim_and_chambray_fabric',
    value: 'denim_and_chambray_fabric',
  },
  { label: 'subSubCategory.jacquard_fabric', key: 'jacquard_fabric', value: 'jacquard_fabric' },
  { label: 'subSubCategory.wax_fabric', key: 'wax_fabric', value: 'wax_fabric' },
  { label: 'subSubCategory.satin_fabric', key: 'satin_fabric', value: 'satin_fabric' },
  { label: 'subSubCategory.suede_fabric', key: 'suede_fabric', value: 'suede_fabric' },
  { label: 'subSubCategory.faux_fur_fabric', key: 'faux_fur_fabric', value: 'faux_fur_fabric' },
  { label: 'subSubCategory.tweed_fabric', key: 'tweed_fabric', value: 'tweed_fabric' },
  { label: 'subSubCategory.muslin_fabric', key: 'muslin_fabric', value: 'muslin_fabric' },
  {
    label: 'subSubCategory.lycra_and_sport_fabric',
    key: 'lycra_and_sport_fabric',
    value: 'lycra_and_sport_fabric',
  },
  {
    label: 'subSubCategory.lace_and_guipure_fabric',
    key: 'lace_and_guipure_fabric',
    value: 'lace_and_guipure_fabric',
  },
  {
    label: 'subSubCategory.english_embroidery_fabric',
    key: 'english_embroidery_fabric',
    value: 'english_embroidery_fabric',
  },
  {
    label: 'subSubCategory.clothing_imitation_leather_fabric',
    key: 'clothing_imitation_leather_fabric',
    value: 'clothing_imitation_leather_fabric',
  },
  {
    label: 'subSubCategory.prince_of_wales_fabric',
    key: 'prince_of_wales_fabric',
    value: 'prince_of_wales_fabric',
  },
  { label: 'subSubCategory.quilted_fabric', key: 'quilted_fabric', value: 'quilted_fabric' },
  { label: 'subSubCategory.swimsuit_fabric', key: 'swimsuit_fabric', value: 'swimsuit_fabric' },
  { label: 'subSubCategory.oxford_fabric', key: 'oxford_fabric', value: 'oxford_fabric' },
  { label: 'subSubCategory.plumetis_fabric', key: 'plumetis_fabric', value: 'plumetis_fabric' },
  { label: 'subSubCategory.piqué_fabric', key: 'piqué_fabric', value: 'piqué_fabric' },
  { label: 'subSubCategory.twill_fabric', key: 'twill_fabric', value: 'twill_fabric' },
  { label: 'subSubCategory.cupro_fabric', key: 'cupro_fabric', value: 'cupro_fabric' },
  { label: 'subSubCategory.modal_fabric', key: 'modal_fabric', value: 'modal_fabric' },
  { label: 'subSubCategory.bamboo_fabric', key: 'bamboo_fabric', value: 'bamboo_fabric' },
  { label: 'subSubCategory.lining_fabric', key: 'lining_fabric', value: 'lining_fabric' },
  { label: 'subSubCategory.interface_fabric', key: 'interface_fabric', value: 'interface_fabric' },
  { label: 'subSubCategory.wadding_fabric', key: 'wadding_fabric', value: 'wadding_fabric' },
  { label: 'subSubCategory.woolen_fabric', key: 'woolen_fabric', value: 'woolen_fabric' },
  { label: 'subSubCategory.sequin_fabric', key: 'sequin_fabric', value: 'sequin_fabric' },
  { label: 'subSubCategory.neoprene_fabric', key: 'neoprene_fabric', value: 'neoprene_fabric' },
  { label: 'subSubCategory.silk_fabric', key: 'silk_fabric', value: 'silk_fabric' },
  {
    label: 'subSubCategory.others_garment_fabrics',
    key: 'others_garment_fabrics',
    value: 'others_garment_fabrics',
  },
];
export const furnishingFabricsSubSubCategory = [
  { label: 'subSubCategory.minky_fabric', key: 'minky_fabric', value: 'minky_fabric' },
  {
    label: 'subSubCategory.fake_leather_furniture',
    key: 'fake_leather_furniture',
    value: 'fake_leather_furniture',
  },
  {
    label: 'subSubCategory.half_braided_fabric',
    key: 'half_braided_fabric',
    value: 'half_braided_fabric',
  },
  {
    label: 'subSubCategory.linen_like_fabric',
    key: 'linen_like_fabric',
    value: 'linen_like_fabric',
  },
  { label: 'subSubCategory.coated_fabric', key: 'coated_fabric', value: 'coated_fabric' },
  {
    label: 'subSubCategory.outdoor_upholstery_fabrics',
    key: 'outdoor_upholstery_fabrics',
    value: 'outdoor_upholstery_fabrics',
  },
  { label: 'subSubCategory.blackout_fabric', key: 'blackout_fabric', value: 'blackout_fabric' },
  { label: 'subSubCategory.foam_', key: 'foam_', value: 'foam_' },
  { label: 'subSubCategory.vinyl', key: 'vinyl', value: 'vinyl' },
  {
    label: 'subSubCategory.furnishing_velvet_fabric',
    key: 'furnishing_velvet_fabric',
    value: 'furnishing_velvet_fabric',
  },
  {
    label: 'subSubCategory.table_protector_and_tablecloth',
    key: 'table_protector_and_tablecloth',
    value: 'table_protector_and_tablecloth',
  },
  { label: 'subSubCategory.cork_fabric', key: 'cork_fabric', value: 'cork_fabric' },
  { label: 'subSubCategory.sequins_fabrics', key: 'sequins_fabrics', value: 'sequins_fabrics' },
  { label: 'subSubCategory.tulle_', key: 'tulle_', value: 'tulle_' },
  { label: 'subSubCategory.organza', key: 'organza', value: 'organza' },
  { label: 'subSubCategory.burlap', key: 'burlap', value: 'burlap' },
  {
    label: 'subSubCategory.furnishing_jacquard_fabric',
    key: 'furnishing_jacquard_fabric',
    value: 'furnishing_jacquard_fabric',
  },
  { label: 'subSubCategory.rag_fabric', key: 'rag_fabric', value: 'rag_fabric' },
  {
    label: 'subSubCategory.fly_screen_fabric',
    key: 'fly_screen_fabric',
    value: 'fly_screen_fabric',
  },
  {
    label: 'subSubCategory.upholstery_fabric_for_chairs',
    key: 'upholstery_fabric_for_chairs',
    value: 'upholstery_fabric_for_chairs',
  },
  {
    label: 'subSubCategory.fabric_for_cushion',
    key: 'fabric_for_cushion',
    value: 'fabric_for_cushion',
  },
  { label: 'subSubCategory.curtain_fabric', key: 'curtain_fabric', value: 'curtain_fabric' },
  { label: 'subSubCategory.sheer_fabric', key: 'sheer_fabric', value: 'sheer_fabric' },
  { label: 'subSubCategory.honeycomb_fabric', key: 'honeycomb_fabric', value: 'honeycomb_fabric' },
  { label: 'subSubCategory.towelling_fabric', key: 'towelling_fabric', value: 'towelling_fabric' },
  {
    label: 'subSubCategory.others_furniture_fabrics',
    key: 'others_furniture_fabrics',
    value: 'others_furniture_fabrics',
  },
];
export const buttonsSubSubCategory = [
  { label: 'subSubCategory.buttons_to_sew', key: 'buttons_to_sew', value: 'buttons_to_sew' },
  { label: 'subSubCategory.jeans_buttons_', key: 'jeans_buttons_', value: 'jeans_buttons_' },
  { label: 'subSubCategory.snap_buttons', key: 'snap_buttons', value: 'snap_buttons' },
  { label: 'subSubCategory.covered_buttons', key: 'covered_buttons', value: 'covered_buttons' },
  { label: 'subSubCategory.wooden_buttons', key: 'wooden_buttons', value: 'wooden_buttons' },
  { label: 'subSubCategory.eyelet', key: 'eyelet', value: 'eyelet' },
  { label: 'subSubCategory.other_buttons', key: 'other_buttons', value: 'other_buttons' },
];
export const trimsSubSubCategory = [
  { label: 'subSubCategory.cords_laces', key: 'cords_laces', value: 'cords_laces' },
  { label: 'subSubCategory.lace_ribbons', key: 'lace_ribbons', value: 'lace_ribbons' },
  { label: 'subSubCategory.elastic_tapes', key: 'elastic_tapes', value: 'elastic_tapes' },
  {
    label: 'subSubCategory.grosgrain_ribbons',
    key: 'grosgrain_ribbons',
    value: 'grosgrain_ribbons',
  },
  { label: 'subSubCategory.braids', key: 'braids', value: 'braids' },
  { label: 'subSubCategory.pipings', key: 'pipings', value: 'pipings' },
  { label: 'subSubCategory.chains', key: 'chains', value: 'chains' },
  { label: 'subSubCategory.straps', key: 'straps', value: 'straps' },
  { label: 'subSubCategory.bias', key: 'bias', value: 'bias' },
  { label: 'subSubCategory.velcro_tapes', key: 'velcro_tapes', value: 'velcro_tapes' },
  { label: 'subSubCategory.other_trims', key: 'other_trims', value: 'other_trims' },
];
export const accessoriesSubSubCategory = [
  { label: 'subSubCategory.sewing_threads', key: 'sewing_threads', value: 'sewing_threads' },
  { label: 'subSubCategory.zippers', key: 'zippers', value: 'zippers' },
  { label: 'subSubCategory.needles_and_pins', key: 'needles_and_pins', value: 'needles_and_pins' },
  {
    label: 'subSubCategory.furnishing_accessories',
    key: 'furnishing_accessories',
    value: 'furnishing_accessories',
  },
  {
    label: 'subSubCategory.underwear_and_swimwear_accessories',
    key: 'underwear_and_swimwear_accessories',
    value: 'underwear_and_swimwear_accessories',
  },
  {
    label: 'subSubCategory.leather_goods_accessories',
    key: 'leather_goods_accessories',
    value: 'leather_goods_accessories',
  },
  { label: 'subSubCategory.belt_buckles', key: 'belt_buckles', value: 'belt_buckles' },
  { label: 'subSubCategory.collars', key: 'collars', value: 'collars' },
  { label: 'subSubCategory.shoulder_pads', key: 'shoulder_pads', value: 'shoulder_pads' },
  {
    label: 'subSubCategory.elbow_and_knee_pads',
    key: 'elbow_and_knee_pads',
    value: 'elbow_and_knee_pads',
  },
  { label: 'subSubCategory.shoulder_straps', key: 'shoulder_straps', value: 'shoulder_straps' },
  { label: 'subSubCategory.badges', key: 'badges', value: 'badges' },
  { label: 'subSubCategory.rings', key: 'rings', value: 'rings' },
  {
    label: 'subSubCategory.rhinestones_and_studs',
    key: 'rhinestones_and_studs',
    value: 'rhinestones_and_studs',
  },
  {
    label: 'subSubCategory.other_accessories',
    key: 'other_accessories',
    value: 'other_accessories',
  },
];
export const machinesSubSubCategory = [
  { label: 'subSubCategory.sewing_machines', key: 'sewing_machines', value: 'sewing_machines' },
  {
    label: 'subSubCategory.overlock_and_coverlock_machines',
    key: 'overlock_and_coverlock_machines',
    value: 'overlock_and_coverlock_machines',
  },
  {
    label: 'subSubCategory.embroidery_machines',
    key: 'embroidery_machines',
    value: 'embroidery_machines',
  },
  {
    label: 'subSubCategory.knitting_machines',
    key: 'knitting_machines',
    value: 'knitting_machines',
  },
  { label: 'subSubCategory.cutting_machines', key: 'cutting_machines', value: 'cutting_machines' },
  { label: 'subSubCategory.heat_press', key: 'heat_press', value: 'heat_press' },
  {
    label: 'subSubCategory.machines_accessories',
    key: 'machines_accessories',
    value: 'machines_accessories',
  },
  { label: 'subSubCategory.other_machines', key: 'other_machines', value: 'other_machines' },
];
export const toolsSubSubCategory = [
  { label: 'subSubCategory.dress_form', key: 'dress_form', value: 'dress_form' },
  {
    label: 'subSubCategory.scissors_and_cutting_tools',
    key: 'scissors_and_cutting_tools',
    value: 'scissors_and_cutting_tools',
  },
  {
    label: 'subSubCategory.tracing_and_measuring_tools',
    key: 'tracing_and_measuring_tools',
    value: 'tracing_and_measuring_tools',
  },
  { label: 'subSubCategory.clips', key: 'clips', value: 'clips' },
  {
    label: 'subSubCategory.pattern_accessories',
    key: 'pattern_accessories',
    value: 'pattern_accessories',
  },
  {
    label: 'subSubCategory.lamps_and_bright_magnifier',
    key: 'lamps_and_bright_magnifier',
    value: 'lamps_and_bright_magnifier',
  },
  { label: 'subSubCategory.other_tools', key: 'other_tools', value: 'other_tools' },
];
export const sewingStorageSubSubCategory = [
  { label: 'subSubCategory.sewing_box', key: 'sewing_box', value: 'sewing_box' },
  { label: 'subSubCategory.sewing_case', key: 'sewing_case', value: 'sewing_case' },
  { label: 'subSubCategory.other_storage', key: 'other_storage', value: 'other_storage' },
];
export const booksSewingPatternsSubSubCategory = [
  { label: 'subSubCategory.sewing_books', key: 'sewing_books', value: 'sewing_books' },
  { label: 'subSubCategory.sewing_magazines', key: 'sewing_magazines', value: 'sewing_magazines' },
  { label: 'subSubCategory.sewing_patterns', key: 'sewing_patterns', value: 'sewing_patterns' },
];
export const subSubCategories = [
  ...clothingFabricsSubSubCategory,
  ...furnishingFabricsSubSubCategory,
  ...buttonsSubSubCategory,
  ...trimsSubSubCategory,
  ...accessoriesSubSubCategory,
  ...machinesSubSubCategory,
  ...toolsSubSubCategory,
  ...sewingStorageSubSubCategory,
  ...booksSewingPatternsSubSubCategory,
];
export const compositionOptions = [
  { label: 'composition.acrylic', key: 'acrylic', value: 'acrylic' },
  { label: 'composition.corn_starch', key: 'corn_starch', value: 'corn_starch' },
  { label: 'composition.bamboo', key: 'bamboo', value: 'bamboo' },
  { label: 'composition.hemp', key: 'hemp', value: 'hemp' },
  { label: 'composition.coco', key: 'coco', value: 'coco' },
  { label: 'composition.cotton', key: 'cotton', value: 'cotton' },
  { label: 'composition.cupro', key: 'cupro', value: 'cupro' },
  { label: 'composition.spandex', key: 'spandex', value: 'spandex' },
  { label: 'composition.burlap', key: 'burlap', value: 'burlap' },
  { label: 'composition.wool', key: 'wool', value: 'wool' },
  { label: 'composition.linen', key: 'linen', value: 'linen' },
  { label: 'composition.lurex', key: 'lurex', value: 'lurex' },
  { label: 'composition.lyocell', key: 'lyocell', value: 'lyocell' },
  { label: 'composition.nylon', key: 'nylon', value: 'nylon' },
  { label: 'composition.straw', key: 'straw', value: 'straw' },
  { label: 'composition.plastic', key: 'plastic', value: 'plastic' },
  { label: 'composition.poly_cotton', key: 'poly_cotton', value: 'poly_cotton' },
  { label: 'composition.polyamide', key: 'polyamide', value: 'polyamide' },
  { label: 'composition.polyester', key: 'polyester', value: 'polyester' },
  {
    label: 'composition.metallic_polyester',
    key: 'metallic_polyester',
    value: 'metallic_polyester',
  },
  { label: 'composition.polyethylene', key: 'polyethylene', value: 'polyethylene' },
  { label: 'composition.polyurethane', key: 'polyurethane', value: 'polyurethane' },
  { label: 'composition.pvc', key: 'pvc', value: 'pvc' },
  { label: 'composition.ramie', key: 'ramie', value: 'ramie' },
  { label: 'composition.silk', key: 'silk', value: 'silk' },
  { label: 'composition.tencel', key: 'tencel', value: 'tencel' },
  { label: 'composition.viscose_and_rayon', key: 'viscose_and_rayon', value: 'viscose_and_rayon' },
  {
    label: 'composition.other_compositions',
    key: 'other_compositions',
    value: 'other_compositions',
  },
];
export const colorOptions = [
  { label: 'colorOptions.silver', key: 'silver', value: 'silver', color: 'silver' },
  { label: 'colorOptions.beige', key: 'beige', value: 'beige', color: 'beige' },
  {
    label: 'colorOptions.white_unbleached',
    key: 'white_unbleached',
    value: 'white_unbleached',
    color: 'white',
    border: 'lightGray',
  },
  { label: 'colorOptions.blue', key: 'blue', value: 'blue', color: 'blue' },
  { label: 'colorOptions.copper', key: 'copper', value: 'copper', color: '#F4A460' },
  { label: 'colorOptions.gold', key: 'gold', value: 'gold', color: 'gold' },
  { label: 'colorOptions.grey', key: 'grey', value: 'grey', color: 'grey' },
  { label: 'colorOptions.yellow', key: 'yellow', value: 'yellow', color: 'yellow' },
  { label: 'colorOptions.brown', key: 'brown', value: 'brown', color: 'brown' },
  { label: 'colorOptions.multicolor', key: 'multicolor', value: 'multicolor', color: 'multi' },
  { label: 'colorOptions.black', key: 'black', value: 'black', color: 'black' },
  { label: 'colorOptions.orange', key: 'orange', value: 'orange', color: 'orange' },
  { label: 'colorOptions.pink', key: 'pink', value: 'pink', color: 'pink' },
  { label: 'colorOptions.red', key: 'red', value: 'red', color: 'red' },
  { label: 'colorOptions.green', key: 'green', value: 'green', color: 'green' },
  { label: 'colorOptions.purple', key: 'purple', value: 'purple', color: 'purple' },
  {
    label: 'colorOptions.other_colors',
    key: 'other_colors',
    value: 'other_colors',
    color: 'multi',
  },
];
export const patternOptions = [
  { label: 'patternOptions.plain', key: 'plain', value: 'plain' },
  { label: 'patternOptions.animal', key: 'animal', value: 'animal' },
  { label: 'patternOptions.camouflage', key: 'camouflage', value: 'camouflage' },
  { label: 'patternOptions.check', key: 'check', value: 'check' },
  { label: 'patternOptions.herringbones', key: 'herringbones', value: 'herringbones' },
  { label: 'patternOptions.hearts', key: 'hearts', value: 'hearts' },
  { label: 'patternOptions.déguisement', key: 'déguisement', value: 'déguisement' },
  { label: 'patternOptions.children', key: 'children', value: 'children' },
  { label: 'patternOptions.ethnic', key: 'ethnic', value: 'ethnic' },
  { label: 'patternOptions.stars', key: 'stars', value: 'stars' },
  { label: 'patternOptions.flowers', key: 'flowers', value: 'flowers' },
  { label: 'patternOptions.folk', key: 'folk', value: 'folk' },
  { label: 'patternOptions.fruits', key: 'fruits', value: 'fruits' },
  { label: 'patternOptions.graphic', key: 'graphic', value: 'graphic' },
  { label: 'patternOptions.naval', key: 'naval', value: 'naval' },
  { label: 'patternOptions.nature', key: 'nature', value: 'nature' },
  { label: 'patternOptions.christmas', key: 'christmas', value: 'christmas' },
  { label: 'patternOptions.oxford', key: 'oxford', value: 'oxford' },
  { label: 'patternOptions.glitters', key: 'glitters', value: 'glitters' },
  { label: 'patternOptions.paisley', key: 'paisley', value: 'paisley' },
  { label: 'patternOptions.characters', key: 'characters', value: 'characters' },
  {
    label: 'patternOptions.houndstooth_fabrics',
    key: 'houndstooth_fabrics',
    value: 'houndstooth_fabrics',
  },
  { label: 'patternOptions.feathers', key: 'feathers', value: 'feathers' },
  { label: 'patternOptions.polka_dots', key: 'polka_dots', value: 'polka_dots' },
  { label: 'patternOptions.prince_of_walles', key: 'prince_of_walles', value: 'prince_of_walles' },
  { label: 'patternOptions.stripes', key: 'stripes', value: 'stripes' },
  { label: 'patternOptions.tartan', key: 'tartan', value: 'tartan' },
  { label: 'patternOptions.tie_and_dye', key: 'tie_and_dye', value: 'tie_and_dye' },
  { label: 'patternOptions.gingham', key: 'gingham', value: 'gingham' },
  { label: 'patternOptions.african', key: 'african', value: 'african' },
  { label: 'patternOptions.halloween', key: 'halloween', value: 'halloween' },
  { label: 'patternOptions.other_patterns', key: 'other_patterns', value: 'other_patterns' },
];
export const weightOptions = [
  { label: 'weightOptions.light', key: 'light', value: 'light' },
  { label: 'weightOptions.medium', key: 'medium', value: 'medium' },
  { label: 'weightOptions.heavy', key: 'heavy', value: 'heavy' },
  { label: "weightOptions.i_don't_know", key: "i_don't_know", value: "i_don't_know" },
];
export const fabricBrandOptions = [
  { label: 'fabricBrandOption.alexander_henry', key: 'alexander_henry', value: 'alexander_henry' },
  {
    label: 'fabricBrandOption.art_gallery_fabrics',
    key: 'art_gallery_fabrics',
    value: 'art_gallery_fabrics',
  },
  { label: 'fabricBrandOption.atelier_27', key: 'atelier_27', value: 'atelier_27' },
  {
    label: 'fabricBrandOption.atelier_brunette',
    key: 'atelier_brunette',
    value: 'atelier_brunette',
  },
  { label: 'fabricBrandOption.atelier_jupe', key: 'atelier_jupe', value: 'atelier_jupe' },
  { label: 'fabricBrandOption.au_maison', key: 'au_maison', value: 'au_maison' },
  { label: 'fabricBrandOption.blend_fabrics', key: 'blend_fabrics', value: 'blend_fabrics' },
  { label: 'fabricBrandOption.camelot_fabrics', key: 'camelot_fabrics', value: 'camelot_fabrics' },
  { label: 'fabricBrandOption.cloud9', key: 'cloud9', value: 'cloud9' },
  { label: 'fabricBrandOption.cotton_+_steel', key: 'cotton_+_steel', value: 'cotton_+_steel' },
  { label: 'fabricBrandOption.dashwood_studio', key: 'dashwood_studio', value: 'dashwood_studio' },
  { label: 'fabricBrandOption.dear_stella', key: 'dear_stella', value: 'dear_stella' },
  {
    label: 'fabricBrandOption.disney,_marvel_&_universal_pictures',
    key: 'disney,_marvel_&_universal_pictures',
    value: 'disney,_marvel_&_universal_pictures',
  },
  { label: 'fabricBrandOption.domotex', key: 'domotex', value: 'domotex' },
  {
    label: 'fabricBrandOption.eglantine_et_zoé',
    key: 'eglantine_et_zoé',
    value: 'eglantine_et_zoé',
  },
  { label: 'fabricBrandOption.fibre_mood', key: 'fibre_mood', value: 'fibre_mood' },
  { label: 'fabricBrandOption.fiona_hewitt', key: 'fiona_hewitt', value: 'fiona_hewitt' },
  { label: 'fabricBrandOption.france_duval', key: 'france_duval', value: 'france_duval' },
  { label: 'fabricBrandOption.froufrou', key: 'froufrou', value: 'froufrou' },
  { label: 'fabricBrandOption.hoffman_fabrics', key: 'hoffman_fabrics', value: 'hoffman_fabrics' },
  { label: 'fabricBrandOption.kaffe_fassett', key: 'kaffe_fassett', value: 'kaffe_fassett' },
  { label: 'fabricBrandOption.katia_fabrics', key: 'katia_fabrics', value: 'katia_fabrics' },
  {
    label: 'fabricBrandOption.la_maison_victor',
    key: 'la_maison_victor',
    value: 'la_maison_victor',
  },
  { label: 'fabricBrandOption.laetibricole', key: 'laetibricole', value: 'laetibricole' },
  {
    label: 'fabricBrandOption.les_toiles_de_la_montagne_noire',
    key: 'les_toiles_de_la_montagne_noire',
    value: 'les_toiles_de_la_montagne_noire',
  },
  { label: 'fabricBrandOption.liberty', key: 'liberty', value: 'liberty' },
  { label: 'fabricBrandOption.lise_tailor', key: 'lise_tailor', value: 'lise_tailor' },
  { label: 'fabricBrandOption.madame_iris', key: 'madame_iris', value: 'madame_iris' },
  { label: 'fabricBrandOption.maison_thevenon', key: 'maison_thevenon', value: 'maison_thevenon' },
  { label: 'fabricBrandOption.makower_uk', key: 'makower_uk', value: 'makower_uk' },
  { label: 'fabricBrandOption.mind_the_maker', key: 'mind_the_maker', value: 'mind_the_maker' },
  { label: 'fabricBrandOption.petit_pan', key: 'petit_pan', value: 'petit_pan' },
  { label: 'fabricBrandOption.poppy', key: 'poppy', value: 'poppy' },
  { label: 'fabricBrandOption.pretty_mercerie', key: 'pretty_mercerie', value: 'pretty_mercerie' },
  {
    label: 'fabricBrandOption.qjutie_kids_collection',
    key: 'qjutie_kids_collection',
    value: 'qjutie_kids_collection',
  },
  { label: 'fabricBrandOption.rico_design', key: 'rico_design', value: 'rico_design' },
  { label: 'fabricBrandOption.safeco', key: 'safeco', value: 'safeco' },
  { label: 'fabricBrandOption.see_you_at_six', key: 'see_you_at_six', value: 'see_you_at_six' },
  { label: 'fabricBrandOption.singulière', key: 'singulière', value: 'singulière' },
  { label: 'fabricBrandOption.soft_cactus', key: 'soft_cactus', value: 'soft_cactus' },
  {
    label: 'fabricBrandOption.studio_walkie_talkie',
    key: 'studio_walkie_talkie',
    value: 'studio_walkie_talkie',
  },
  { label: 'fabricBrandOption.tilda', key: 'tilda', value: 'tilda' },
  {
    label: 'fabricBrandOption.timeless_treasures',
    key: 'timeless_treasures',
    value: 'timeless_treasures',
  },
  { label: 'fabricBrandOption.warner_bros', key: 'warner_bros', value: 'warner_bros' },
  {
    label: 'fabricBrandOption.other_fabric_brands',
    key: 'other_fabric_brands',
    value: 'other_fabric_brands',
  },
];
export const swatchConditionsOptions = [
  { label: 'swatchConditionOption.new', key: 'new', value: 'new' },
  { label: 'swatchConditionOption.washed', key: 'washed', value: 'washed' },
  {
    label: 'swatchConditionOption.new_with_defects',
    key: 'new_with_defects',
    value: 'new_with_defects',
  },
  {
    label: 'swatchConditionOption.washed_with_defects',
    key: 'washed_with_defects',
    value: 'washed_with_defects',
  },
];
export const materialOptions = [
  {
    label: 'materialOption.polyester_and_resin',
    key: 'polyester_and_resin',
    value: 'polyester_and_resin',
  },
  { label: 'materialOption.metal', key: 'metal', value: 'metal' },
  { label: 'materialOption.wood_and_coco', key: 'wood_and_coco', value: 'wood_and_coco' },
  { label: 'materialOption.shell', key: 'shell', value: 'shell' },
  { label: 'materialOption.others_material', key: 'others_material', value: 'others_material' },
];

export const notionsConditions = [
  { label: 'notionsCondition.new', key: 'new', value: 'new' },
  { label: 'notionsCondition.new_with_defect', key: 'new_with_defect', value: 'new_with_defect' },
  {
    label: 'notionsCondition.vintage_very_good',
    key: 'vintage_very_good',
    value: 'vintage_very_good',
  },
  { label: 'notionsCondition.vintage_good', key: 'vintage_good', value: 'vintage_good' },
  {
    label: 'notionsCondition.vintage_satisfactory',
    key: 'vintage_satisfactory',
    value: 'vintage_satisfactory',
  },
];

export const typeOptions = [
  { label: 'typeOption.separable', key: 'separable', value: 'separable' },
  { label: 'typeOption.non_separable', key: 'non_separable', value: 'non_separable' },
];
export const machineBrandOptions = [
  { label: 'machineBrand.bernina', key: 'bernina', value: 'bernina' },
  { label: 'machineBrand.brother', key: 'brother', value: 'brother' },
  { label: 'machineBrand.cricut', key: 'cricut', value: 'cricut' },
  { label: 'machineBrand.elna', key: 'elna', value: 'elna' },
  { label: 'machineBrand.husqvarna', key: 'husqvarna', value: 'husqvarna' },
  { label: 'machineBrand.janome', key: 'janome', value: 'janome' },
  { label: 'machineBrand.juki', key: 'juki', value: 'juki' },
  { label: 'machineBrand.lidl', key: 'lidl', value: 'lidl' },
  { label: 'machineBrand.necchi', key: 'necchi', value: 'necchi' },
  { label: 'machineBrand.pfaff', key: 'pfaff', value: 'pfaff' },
  { label: 'machineBrand.schmetz', key: 'schmetz', value: 'schmetz' },
  { label: 'machineBrand.silhouette', key: 'silhouette', value: 'silhouette' },
  { label: 'machineBrand.silver', key: 'silver', value: 'silver' },
  { label: 'machineBrand.singer', key: 'singer', value: 'singer' },
];
export const patternBrandOptions = [
  {
    label: 'patternBrandOption.aime_comme_marie',
    key: 'aime_comme_marie',
    value: 'aime_comme_marie',
  },
  { label: 'patternBrandOption.amy_butler', key: 'amy_butler', value: 'amy_butler' },
  { label: 'patternBrandOption.anne_kerdilès', key: 'anne_kerdilès', value: 'anne_kerdilès' },
  {
    label: 'patternBrandOption.atelier_de_guillemette',
    key: 'atelier_de_guillemette',
    value: 'atelier_de_guillemette',
  },
  { label: 'patternBrandOption.atelier_jupe', key: 'atelier_jupe', value: 'atelier_jupe' },
  { label: 'patternBrandOption.atelier_scämmit', key: 'atelier_scämmit', value: 'atelier_scämmit' },
  { label: 'patternBrandOption.aude_loriot', key: 'aude_loriot', value: 'aude_loriot' },
  { label: 'patternBrandOption.blousette_rose', key: 'blousette_rose', value: 'blousette_rose' },
  { label: 'patternBrandOption.burda', key: 'burda', value: 'burda' },
  { label: 'patternBrandOption.butterick', key: 'butterick', value: 'butterick' },
  { label: 'patternBrandOption.by_hand_london', key: 'by_hand_london', value: 'by_hand_london' },
  { label: 'patternBrandOption.cest_dimanche', key: 'cest_dimanche', value: 'cest_dimanche' },
  {
    label: 'patternBrandOption.cest_moi_le_patron',
    key: 'cest_moi_le_patron',
    value: 'cest_moi_le_patron',
  },
  {
    label: 'patternBrandOption.christine_haynes',
    key: 'christine_haynes',
    value: 'christine_haynes',
  },
  { label: 'patternBrandOption.chut_charlotte', key: 'chut_charlotte', value: 'chut_charlotte' },
  { label: 'patternBrandOption.citronille', key: 'citronille', value: 'citronille' },
  { label: 'patternBrandOption.cléa_&_roman', key: 'cléa_&_roman', value: 'cléa_&_roman' },
  {
    label: 'patternBrandOption.clematisse_pattern',
    key: 'clematisse_pattern',
    value: 'clematisse_pattern',
  },
  {
    label: 'patternBrandOption.colette_patterns',
    key: 'colette_patterns',
    value: 'colette_patterns',
  },
  {
    label: 'patternBrandOption.cousette_pattern',
    key: 'cousette_pattern',
    value: 'cousette_pattern',
  },
  {
    label: 'patternBrandOption.couture_générale',
    key: 'couture_générale',
    value: 'couture_générale',
  },
  { label: 'patternBrandOption.couturette', key: 'couturette', value: 'couturette' },
  {
    label: 'patternBrandOption.cozy_little_world',
    key: 'cozy_little_world',
    value: 'cozy_little_world',
  },
  { label: 'patternBrandOption.craftsy', key: 'craftsy', value: 'craftsy' },
  { label: 'patternBrandOption.deer_&_doe', key: 'deer_&_doe', value: 'deer_&_doe' },
  {
    label: 'patternBrandOption.delphine_et_morissette',
    key: 'delphine_et_morissette',
    value: 'delphine_et_morissette',
  },
  {
    label: 'patternBrandOption.dessine_moi_un_patron',
    key: 'dessine_moi_un_patron',
    value: 'dessine_moi_un_patron',
  },
  {
    label: 'patternBrandOption.deuxième_arrondissement',
    key: 'deuxième_arrondissement',
    value: 'deuxième_arrondissement',
  },
  { label: 'patternBrandOption.dp_studio', key: 'dp_studio', value: 'dp_studio' },
  { label: 'patternBrandOption.dress_your_body', key: 'dress_your_body', value: 'dress_your_body' },
  {
    label: 'patternBrandOption.edelweiss_patterns_',
    key: 'edelweiss_patterns_',
    value: 'edelweiss_patterns_',
  },
  { label: 'patternBrandOption.evadress', key: 'evadress', value: 'evadress' },
  { label: 'patternBrandOption.fibre_mood', key: 'fibre_mood', value: 'fibre_mood' },
  { label: 'patternBrandOption.figgys', key: 'figgys', value: 'figgys' },
  { label: 'patternBrandOption.folkwear', key: 'folkwear', value: 'folkwear' },
  { label: 'patternBrandOption.gasparine', key: 'gasparine', value: 'gasparine' },
  {
    label: 'patternBrandOption.grainline_studio',
    key: 'grainline_studio',
    value: 'grainline_studio',
  },
  { label: 'patternBrandOption.heidi_&_finn', key: 'heidi_&_finn', value: 'heidi_&_finn' },
  {
    label: 'patternBrandOption.her_little_world',
    key: 'her_little_world',
    value: 'her_little_world',
  },
  { label: 'patternBrandOption.hot_patterns', key: 'hot_patterns', value: 'hot_patterns' },
  { label: 'patternBrandOption.i_am_patterns', key: 'i_am_patterns', value: 'i_am_patterns' },
  { label: 'patternBrandOption.indygo_junction', key: 'indygo_junction', value: 'indygo_junction' },
  { label: 'patternBrandOption.jalie', key: 'jalie', value: 'jalie' },
  { label: 'patternBrandOption.jamie_christina', key: 'jamie_christina', value: 'jamie_christina' },
  { label: 'patternBrandOption.kwik_sew', key: 'kwik_sew', value: 'kwik_sew' },
  { label: "patternBrandOption.l'enfant_roi", key: "l'enfant_roi", value: "l'enfant_roi" },
  {
    label: 'patternBrandOption.l’atelier_des_cigognes',
    key: 'l’atelier_des_cigognes',
    value: 'l’atelier_des_cigognes',
  },
  {
    label: 'patternBrandOption.la_maison_victor',
    key: 'la_maison_victor',
    value: 'la_maison_victor',
  },
  {
    label: 'patternBrandOption.le_laboratoire_familial',
    key: 'le_laboratoire_familial',
    value: 'le_laboratoire_familial',
  },
  {
    label: 'patternBrandOption.les_ateliers_caroline',
    key: 'les_ateliers_caroline',
    value: 'les_ateliers_caroline',
  },
  { label: 'patternBrandOption.les_bg', key: 'les_bg', value: 'les_bg' },
  {
    label: 'patternBrandOption.les_lubies_de_cadia',
    key: 'les_lubies_de_cadia',
    value: 'les_lubies_de_cadia',
  },
  { label: 'patternBrandOption.les_patronnes', key: 'les_patronnes', value: 'les_patronnes' },
  {
    label: 'patternBrandOption.les_robes_volages',
    key: 'les_robes_volages',
    value: 'les_robes_volages',
  },
  { label: 'patternBrandOption.lisette', key: 'lisette', value: 'lisette' },
  { label: 'patternBrandOption.lot_of_things', key: 'lot_of_things', value: 'lot_of_things' },
  {
    label: 'patternBrandOption.louis_antoinette',
    key: 'louis_antoinette',
    value: 'louis_antoinette',
  },
  { label: 'patternBrandOption.madame_maman', key: 'madame_maman', value: 'madame_maman' },
  { label: 'patternBrandOption.maison_fauve', key: 'maison_fauve', value: 'maison_fauve' },
  {
    label: 'patternBrandOption.mariadenmark_onion_patterns',
    key: 'mariadenmark_onion_patterns',
    value: 'mariadenmark_onion_patterns',
  },
  { label: 'patternBrandOption.mc_calls', key: 'mc_calls', value: 'mc_calls' },
  { label: 'patternBrandOption.megan_nielsen', key: 'megan_nielsen', value: 'megan_nielsen' },
  {
    label: 'patternBrandOption.merchant_&_mills',
    key: 'merchant_&_mills',
    value: 'merchant_&_mills',
  },
  {
    label: 'patternBrandOption.michelle_patterns',
    key: 'michelle_patterns',
    value: 'michelle_patterns',
  },
  { label: 'patternBrandOption.mlm_patrons', key: 'mlm_patrons', value: 'mlm_patrons' },
  { label: 'patternBrandOption.mod_kits', key: 'mod_kits', value: 'mod_kits' },
  { label: 'patternBrandOption.named_clothing', key: 'named_clothing', value: 'named_clothing' },
  { label: 'patternBrandOption.nanöo', key: 'nanöo', value: 'nanöo' },
  { label: 'patternBrandOption.new_look', key: 'new_look', value: 'new_look' },
  { label: 'patternBrandOption.oliver_+_s', key: 'oliver_+_s', value: 'oliver_+_s' },
  { label: 'patternBrandOption.ottobre', key: 'ottobre', value: 'ottobre' },
  { label: 'patternBrandOption.p&m_patterns', key: 'p&m_patterns', value: 'p&m_patterns' },
  { label: 'patternBrandOption.papercut', key: 'papercut', value: 'papercut' },
  {
    label: 'patternBrandOption.papercut_patterns',
    key: 'papercut_patterns',
    value: 'papercut_patterns',
  },
  {
    label: 'patternBrandOption.papillon_et_mandarine',
    key: 'papillon_et_mandarine',
    value: 'papillon_et_mandarine',
  },
  { label: 'patternBrandOption.patron_ikatee', key: 'patron_ikatee', value: 'patron_ikatee' },
  { label: 'patternBrandOption.pattern_runway', key: 'pattern_runway', value: 'pattern_runway' },
  { label: 'patternBrandOption.pauline_alice', key: 'pauline_alice', value: 'pauline_alice' },
  { label: 'patternBrandOption.petit_faune', key: 'petit_faune', value: 'petit_faune' },
  { label: 'patternBrandOption.pretty_mercerie', key: 'pretty_mercerie', value: 'pretty_mercerie' },
  { label: 'patternBrandOption.ready_to_sew', key: 'ready_to_sew', value: 'ready_to_sew' },
  {
    label: 'patternBrandOption.république_du_chiffon',
    key: 'république_du_chiffon',
    value: 'république_du_chiffon',
  },
  { label: 'patternBrandOption.salme_sewing', key: 'salme_sewing', value: 'salme_sewing' },
  {
    label: 'patternBrandOption.sew_chic_patterns',
    key: 'sew_chic_patterns',
    value: 'sew_chic_patterns',
  },
  { label: 'patternBrandOption.sew_liberated', key: 'sew_liberated', value: 'sew_liberated' },
  { label: 'patternBrandOption.sew_liberated', key: 'sew_liberated', value: 'sew_liberated' },
  { label: 'patternBrandOption.sewaholic', key: 'sewaholic', value: 'sewaholic' },
  { label: 'patternBrandOption.shwin_designs', key: 'shwin_designs', value: 'shwin_designs' },
  { label: 'patternBrandOption.simplicity', key: 'simplicity', value: 'simplicity' },
  {
    label: 'patternBrandOption.straight_stitch_society',
    key: 'straight_stitch_society',
    value: 'straight_stitch_society',
  },
  { label: 'patternBrandOption.stylearc', key: 'stylearc', value: 'stylearc' },
  { label: 'patternBrandOption.super_bison', key: 'super_bison', value: 'super_bison' },
  { label: 'patternBrandOption.tamanegi-kobo', key: 'tamanegi-kobo', value: 'tamanegi-kobo' },
  { label: 'patternBrandOption.truly_victorian', key: 'truly_victorian', value: 'truly_victorian' },
  { label: 'patternBrandOption.u_handbag', key: 'u_handbag', value: 'u_handbag' },
  { label: 'patternBrandOption.valantoine', key: 'valantoine', value: 'valantoine' },
  { label: 'patternBrandOption.vanessa_pouzet', key: 'vanessa_pouzet', value: 'vanessa_pouzet' },
  {
    label: 'patternBrandOption.violette_field_threads',
    key: 'violette_field_threads',
    value: 'violette_field_threads',
  },
  { label: 'patternBrandOption.vogue', key: 'vogue', value: 'vogue' },
  { label: 'patternBrandOption.wear_lemonade', key: 'wear_lemonade', value: 'wear_lemonade' },
  {
    label: 'patternBrandOption.autre_marque_de_patron',
    key: 'autre_marque_de_patron',
    value: 'autre_marque_de_patron',
  },
];
export const machineAndToolConditions = [
  {
    label: 'machineToolCondition.new_in_original_packaging',
    key: 'new_in_original_packaging',
    value: 'new_in_original_packaging',
  },
  {
    label: 'machineToolCondition.new_without_original_packging',
    key: 'new_without_original_packging',
    value: 'new_without_original_packging',
  },
  { label: 'machineToolCondition.very_good', key: 'very_good', value: 'very_good' },
  { label: 'machineToolCondition.good', key: 'good', value: 'good' },
  { label: 'machineToolCondition.satisfactory', key: 'satisfactory', value: 'satisfactory' },
];
export const filters = [
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'trims_length',
    label: 'trimsLength.FilterLabel',
    type: 'CustomFilter',
    group: 'primary',
    symbol: 'm',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['pritrims_lengthce'],"
    queryParamNames: ['pub_trims_length'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 25,
      step: 1,
    },
  },
  {
    id: 'trims_width',
    label: 'trimsWidth.filterLabel',
    type: 'CustomFilter',
    group: 'primary',
    symbol: 'mm',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['pub_trims_width'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 100,
      step: 1,
    },
  },
  {
    id: 'button_size',
    label: 'buttonSize.filterLabel',
    type: 'CustomFilter',
    group: 'primary',
    symbol: 'mm',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['pub_button_size'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 80,
      step: 1,
    },
  },
  {
    id: 'swatch_length',
    label: 'swatchLength.filterLabel',
    type: 'CustomFilter',
    group: 'primary',
    symbol: 'cm',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['pub_swatch_length'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 500,
      step: 1,
    },
  },
  {
    id: 'fabrics_width',
    label: 'fabricsWidth.filterLabel',
    type: 'CustomFilter',
    group: 'primary',
    symbol: 'cm',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['pub_fabrics_width'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 1,
      max: 300,
      step: 1,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },

  {
    id: 'category',
    label: 'categoryFilter.label',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: categories,
    },
  },
  {
    id: 'subCategory',
    label: 'subcategoryFilter.label',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_subcategory'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: notionsSubCategory,
    },
  },
  {
    id: 'subSubCategory',
    label: 'subSubcategoryFilter.label',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_subSubCategory'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: clothingFabricsSubSubCategory,
    },
  },
  {
    id: 'composition',
    label: 'compositionFilter.label',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_composition'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'multi-enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: compositionOptions,
    },
  },
  {
    id: 'color',
    label: 'colorFilter.label',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_color'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'multi-enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: colorOptions,
    },
  },
  {
    id: 'patterns',
    label: 'patternsFilter.label',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_patterns'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: patternOptions,
    },
  },
  {
    id: 'weight',
    label: 'weightFilter.label',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_weight'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: weightOptions,
    },
  },
  {
    id: 'fabric_brands',
    label: 'fabricBrandsFilter.label',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_fabric_brands'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: fabricBrandOptions,
    },
  },
  {
    id: 'swatch_condition',
    label: 'swatchConditionFilter.label',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_swatch_condition'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: swatchConditionsOptions,
    },
  },
  {
    id: 'buttons_material',
    label: 'buttonsMaterialFilter.label',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_buttons_material'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: materialOptions,
    },
  },
  {
    id: 'notions_condition',
    label: 'notionsConditionFilter.label',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_notions_condition'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: notionsConditions,
    },
  },
  {
    id: 'zipper_type',
    label: 'zipperTypeFilter.label',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_zipper_type'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: typeOptions,
    },
  },
  {
    id: 'machine_brands',
    label: 'machineBrandFilter.label',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_machine_brands'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: machineBrandOptions,
    },
  },
  {
    id: 'pattern_brand',
    label: 'patternBrandFilter.label',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_pattern_brand'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: patternBrandOptions,
    },
  },
  {
    id: 'machine_condition',
    label: 'machineConditionFilter.label',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_machine_condition'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: machineAndToolConditions,
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    { key: 'createdAt', label: 'config.marketplace-custom-config.newestSort' },
    { key: '-createdAt', label: 'config.marketplace-custom-config.oldestSortLabel' },
    { key: '-price', label: 'config.marketplace-custom-config.lowestPrice' },
    { key: 'price', label: 'config.marketplace-custom-config.highestPrice' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    {
      key: 'relevance',
      label: 'Relevance',
      longLabel: 'config.marketplace-custom-config.relevanceLabel',
    },
  ],
};

export const listing = {
  // These should be listing details from public data with schema type: enum
  // SectionDetailsMaybe component shows these on listing page.
  enumFieldDetails: ['size', 'brand', 'category'],
};
