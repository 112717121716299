import React from 'react';
import css from './BatchListings.module.css';
import { useSelector } from 'react-redux';
import { IconSpinner, ListingCard, NamedLink, PaginationLinks } from '../../components';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { parse } from '../../util/urlHelpers';
import { useIntl } from 'react-intl';
const BatchListings = props => {
  const { isMapVariant = false, listingId, isOwnListing, author } = props;
  const classes = classNames(css.root);
  const state = useSelector(state => state);
  const history = useHistory();
  const { location } = history || {};
  const intl = useIntl();
  const { search } = location || {};
  const queryParams = parse(search);
  const page = queryParams ? queryParams.page : 1;
  const { allListingInProgress, allListingError, allListingIds, pagination } = state.ListingPage;
  const listings = getListingsById(state, allListingIds);
  const { totalItems = 0 } = pagination || {};
  const authorId = author?.id?.uuid;
  const discounts = author?.attributes?.profile?.publicData?.discounts || [];
  const isDiscountEnabled = author?.attributes?.profile?.publicData?.discount || false;
  const maxDiscount = Math.max(...discounts.map(d => d.percentage), 0);

  // console.log('allListingIds', allListingIds);
  // console.log('authorId', authorId);
  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="ListingPage"
        pagePathParams={{ id: listingId?.uuid, slug: 'slug' }}
        pageSearchParams={queryParams}
        pagination={pagination}
      />
    ) : null;

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  return listings?.length > 1 ? (
    <div className={classes}>
      <div className={css.header}>
        {totalItems > 0 ? (
          <p className={css.totalItems}>
            {totalItems} {intl.formatMessage({ id: 'ListingPage.BatchListings.availableItems' })}
          </p>
        ) : null}
        <div className={css.headerContainer}>
          <div className={css.left}>
            <h3 className={css.buyBatch}>
              {intl.formatMessage({ id: 'ListingPage.BatchListings.buyBatch' })}
            </h3>

            <p className={css.discount}>
              {isDiscountEnabled ? (
                <>
                  {intl.formatMessage(
                    { id: 'ListingPage.BatchListings.maximumDiscount' },
                    { maxDiscount: maxDiscount }
                  )}
                </>
              ) : null}
            </p>
          </div>
          <div className={css.right}>
            {isOwnListing ? null : (
              <NamedLink name="BatchListPage" className={css.button} params={{ id: authorId }}>
                {intl.formatMessage({ id: 'ListingPage.BatchListings.buyABatch' })}
              </NamedLink>
            )}
          </div>
        </div>
      </div>
      {allListingError ? (
        <p className={css.error}>
          {allListingError?.message || "Sorry, we couldn't load the listings for this page"}
        </p>
      ) : null}
      <div className={css.listingCards}>
        {allListingInProgress ? (
          <div className={css.loading}>
            <IconSpinner />
          </div>
        ) : (
          listings.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes(isMapVariant)}
            />
          ))
        )}
      </div>
      {paginationLinks}
    </div>
  ) : null;
};

export default BatchListings;
