import React, { useRef } from 'react';
import { bool, func, number, string } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';

import config from '../../../config';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import {
  composeValidators,
  moneySubUnitAmountAtLeast,
  numberAtLeast,
  required,
} from '../../../util/validators';

import {
  Form,
  FieldSelect,
  FieldTextInput,
  InlineTextButton,
  PrimaryButton,
  FieldRadioButton,
  FieldCurrencyInput,
  SecondaryButton,
} from '../../../components';

import EstimatedCustomerBreakdownMaybe from '../EstimatedCustomerBreakdownMaybe';

import css from './ProductOrderForm.module.css';
import { formatMoney } from '../../../util/currency';
import useSdkTypes from '../../../hooks/useSdkTypes';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist } from '../../../containers/WishlistPage/WishlistPage.duck';

const renderForm = formRenderProps => {
  const {
    // FormRenderProps from final-form
    handleSubmit,
    form: formApi,

    // Custom props passed to the form component
    intl,
    formId,
    currentStock,
    hasMultipleDeliveryMethods,
    listingId,
    isOwnListing,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    values,
    walletOptionDisabled,
    fetchBalanceInProgress,
    balance,
    listing,
    cardEnabled,
    walletAndCardEnabled,
    walletEnabled,
    walletBalance,
    loading,
    currentUser,
    addWishlistInProgress,
    addWishlistError,
    userLoading,
    wishlistIds,
    isBatchListing,
  } = formRenderProps;
  const dispatch = useDispatch();
  const { Money } = useSdkTypes();
  const useNavigate = useHistory();
  const handleOnChange = formValues => {
    const { quantity: quantityRaw = 1, deliveryMethod, paymentMethod } = formValues.values;

    const quantity = Number.parseInt(quantityRaw, 10);
    const isBrowser = typeof window !== 'undefined';

    //TODO: amount to be paid by customer via card when method is card_and_wallet
    const cardPaymentAmountMaybe =
      paymentMethod == config.PAYMENT_METHODS.CARD_WALLET
        ? { amountToBePaid: Number(walletBalance) }
        : {};

    if (
      isBrowser &&
      quantity &&
      deliveryMethod &&
      !fetchLineItemsInProgress &&
      !fetchBalanceInProgress &&
      paymentMethod
    ) {
      onFetchTransactionLineItems({
        orderData: { paymentMethod, quantity, deliveryMethod, ...cardPaymentAmountMaybe },
        listingId,
        isOwnListing,
      });
    }
  };

  // In case quantity and deliveryMethod are missing focus on that select-input.
  // Otherwise continue with the default handleSubmit function.
  const handleFormSubmit = e => {
    if (!currentUser) {
      e.preventDefault();
      useNavigate.push('/login');
    }
    const { quantity, deliveryMethod } = values || {};
    if (!quantity || quantity < 1) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('quantity');
      formApi.focus('quantity');
    } else if (!deliveryMethod) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('deliveryMethod');
      formApi.focus('deliveryMethod');
    } else {
      handleSubmit(e);
    }
  };

  const breakdownData = {};
  const showBreakdown =
    breakdownData &&
    lineItems &&
    !fetchLineItemsInProgress &&
    !fetchLineItemsError &&
    !fetchBalanceInProgress;
  const breakdown = showBreakdown ? (
    <div className={css.breakdownWrapper}>
      <h3>
        <FormattedMessage id="ProductOrderForm.breakdownTitle" />
      </h3>
      <EstimatedCustomerBreakdownMaybe
        unitType={config.lineItemUnitType}
        breakdownData={breakdownData}
        lineItems={lineItems}
      />
    </div>
  ) : null;

  const showContactUser = typeof onContactUser === 'function';

  const onClickContactUser = e => {
    e.preventDefault();
    onContactUser();
  };

  const contactSellerLink = (
    <InlineTextButton onClick={onClickContactUser}>
      <FormattedMessage id="ProductOrderForm.finePrintNoStockLinkText" />
    </InlineTextButton>
  );
  const quantityRequiredMsg = intl.formatMessage({ id: 'ProductOrderForm.quantityRequired' });

  const hasStock = currentStock && currentStock > 0;
  // TODO: PLEASE CHANGE THIS BEFORE LIVE ENVIRONMENT
  const quantities = hasStock ? [...Array(currentStock).keys()].map(i => i + 1) : [];
  const hasNoStockLeft = typeof currentStock != null && currentStock === 0;
  const hasOneItemLeft = typeof currentStock != null && currentStock === 1;

  const submitInProgress = fetchLineItemsInProgress || loading;
  const submitDisabled = !hasStock || !values?.paymentMethod;

  const priceRequired = required(
    intl.formatMessage({ id: 'ProductOrderForm.walletAmountRequired' })
  );
  const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
  const minPriceRequired = moneySubUnitAmountAtLeast(
    intl.formatMessage(
      {
        id: 'ProductOrderForm.amountTooLow',
      },
      {
        minPrice: formatMoney(intl, minPrice),
      }
    ),
    config.listingMinimumPriceSubUnits
  );

  // const maxPrice = new Money(balance.Amount, balance.Currency);

  // const maxPriceRequired = moneySubUnitAmountAtMost(
  //   intl.formatMessage(
  //     {
  //       id: 'PayoutForm.amountTooHigh',
  //     },
  //     {
  //       maxPrice: formatMoney(intl, maxPrice),
  //     }
  //   ),
  //   balance.Amount
  // );

  const priceValidators = config.listingMinimumPriceSubUnits
    ? composeValidators(
        priceRequired,
        minPriceRequired
        // , maxPriceRequired
      )
    : priceRequired;

  // console.log({ walletAndCardEnabled, cardEnabled, walletEnabled, balance });

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormSpy subscription={{ values: true }} onChange={handleOnChange} />
      <div className="mx-8 sm:mx-0">
        <p className="m-0">{intl.formatMessage({ id: 'ProductOrderForm.paymentMethodsTitle' })}</p>

        <div className="flex flex-col">
          {/* {walletOptionDisabled ? (
            walletEnabled ? (
              <FieldRadioButton
                id="card"
                name="paymentMethod"
                value={config.PAYMENT_METHODS.CARD}
                label="Card"
              />
            ) : null
          ) : ( */}
          <>
            {walletEnabled ? (
              <FieldRadioButton
                id="wallet"
                name="paymentMethod"
                value={config.PAYMENT_METHODS.WALLET}
                label={intl.formatMessage({ id: 'ProductOrderForm.ProductOrderForm.walletLabel' })}
                disabled={walletOptionDisabled}
              />
            ) : null}
            {cardEnabled ? (
              <FieldRadioButton
                id="card"
                name="paymentMethod"
                value={config.PAYMENT_METHODS.CARD}
                label={intl.formatMessage({ id: 'ProductOrderForm.ProductOrderForm.cardLabel' })}
              />
            ) : null}
            {walletAndCardEnabled ? (
              <FieldRadioButton
                id="card_and_wallet"
                name="paymentMethod"
                value={config.PAYMENT_METHODS.CARD_WALLET}
                label={intl.formatMessage({
                  id: 'ProductOrderForm.ProductOrderForm.walletAndCardLabel',
                })}
                disabled={walletOptionDisabled}
              />
            ) : null}
            {/* {values.paymentMethod == 'card_and_wallet' && (
                <div>
                  <label htmlFor="wallet_amount" className="">
                    <p>Wallet Amount</p>
                  </label>
                  <FieldCurrencyInput
                    currencyConfig={config.currencyConfig}
                    id="wallet_amount"
                    name="wallet_amount"
                    validate={priceValidators}
                    placeholder={'$2.00'}
                  />
                </div>
              )} */}
            {/* </>
          )} */}
          </>
        </div>
      </div>
      {/* {hasNoStockLeft ? null : hasOneItemLeft ? (
        <FieldTextInput
          id={`${formId}.quantity`}
          className={css.quantityField}
          name="quantity"
          type="hidden"
          validate={numberAtLeast(quantityRequiredMsg, 1)}
        />
      ) : (
        <FieldSelect
          id={`${formId}.quantity`}
          className={css.quantityField}
          name="quantity"
          disabled={!hasStock}
          label={intl.formatMessage({ id: 'ProductOrderForm.quantityLabel' })}
          validate={numberAtLeast(quantityRequiredMsg, 1)}
        >
          <option disabled value="">
            {intl.formatMessage({ id: 'ProductOrderForm.selectQuantityOption' })}
          </option>
          {quantities.map(quantity => (
            <option key={quantity} value={quantity}>
              {intl.formatMessage({ id: 'ProductOrderForm.quantityOption' }, { quantity })}
            </option>
          ))}
        </FieldSelect>
      )} */}
      {/* {hasNoStockLeft ? null : hasMultipleDeliveryMethods ? (
        <FieldSelect
          id={`${formId}.deliveryMethod`}
          className={css.deliveryField}
          name="deliveryMethod"
          disabled={!hasStock}
          label={intl.formatMessage({ id: 'ProductOrderForm.deliveryMethodLabel' })}
          validate={required(intl.formatMessage({ id: 'ProductOrderForm.deliveryMethodRequired' }))}
        >
          <option disabled value="">
            {intl.formatMessage({ id: 'ProductOrderForm.selectDeliveryMethodOption' })}
          </option>
          <option value={'pickup'}>
            {intl.formatMessage({ id: 'ProductOrderForm.pickupOption' })}
          </option>
          <option value={'shipping'}>
            {intl.formatMessage({ id: 'ProductOrderForm.shippingOption' })}
          </option>
        </FieldSelect>
      ) : (
        <div className={css.deliveryField}>
          <label>{intl.formatMessage({ id: 'ProductOrderForm.deliveryMethodLabel' })}</label>
          <p className={css.singleDeliveryMethodSelected}>
            {values.deliveryMethod === 'shipping'
              ? intl.formatMessage({ id: 'ProductOrderForm.shippingOption' })
              : intl.formatMessage({ id: 'ProductOrderForm.pickupOption' })}
          </p>
          <FieldTextInput
            id={`${formId}.deliveryMethod`}
            className={css.deliveryField}
            name="deliveryMethod"
            type="hidden"
          />
        </div>
      )} */}
      {breakdown}
      <div className={css.submitButton}>
        <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
          {hasStock ? (
            <FormattedMessage id="ProductOrderForm.ctaButton" />
          ) : (
            <FormattedMessage id="ProductOrderForm.ctaButtonNoStock" />
          )}
        </PrimaryButton>
      </div>
      <p className={css.finePrint}>
        {hasStock ? (
          <FormattedMessage id="ProductOrderForm.finePrint" />
        ) : showContactUser ? (
          <FormattedMessage id="ProductOrderForm.finePrintNoStock" values={{ contactSellerLink }} />
        ) : null}
      </p>
      {currentUser && !isBatchListing ? (
        <SecondaryButton
          type="button"
          className={css.wishlistButton}
          inProgress={addWishlistInProgress || userLoading}
          onClick={() => {
            dispatch(addToWishlist(listingId.uuid));
          }}
        >
          {wishlistIds.includes(listingId.uuid) ? (
            intl.formatMessage({ id: 'ProductOrderForm.ProductOrderForm.removeFavorites' })
          ) : (
            <FormattedMessage id="ProductOrderForm.ProductOrderForm.addToFavoritesLabel" />
          )}
        </SecondaryButton>
      ) : null}
    </Form>
  );
};

const ProductOrderForm = props => {
  const intl = useIntl();
  const {
    price,
    currentStock,
    pickupEnabled,
    shippingEnabled,
    listing,
    balance,
    userHasCreatedWallet,
    loading,
  } = props;

  // Should not happen for listings that go through EditListingWizard.
  // However, this might happen for imported listings.
  if (!pickupEnabled && !shippingEnabled) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.noDeliveryMethodSet" />
      </p>
    );
  }

  if (!price) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingPriceMissing" />
      </p>
    );
  }
  // if (price.currency !== config.currency) {
  //   return (
  //     <p className={css.error}>
  //       <FormattedMessage id="ProductOrderForm.listingCurrencyInvalid" />
  //     </p>
  //   );
  // }

  // TODO: PLEASE CHANGE THIS BEFORE LIVE ENVIRONMENT
  // const hasOneItemLeft = currentStock && currentStock === 1;
  const hasOneItemLeft = currentStock && currentStock >= 1;
  const quantityMaybe = hasOneItemLeft ? { quantity: '1' } : {};
  const singleDeliveryMethodAvailableMaybe =
    shippingEnabled && !pickupEnabled
      ? { deliveryMethod: 'shipping' }
      : !shippingEnabled && pickupEnabled
      ? { deliveryMethod: 'pickup' }
      : {};
  const hasMultipleDeliveryMethods = pickupEnabled && shippingEnabled;

  const listingPrice = listing.attributes.price.amount;
  const walletBalance = balance;
  console.log('walletBalance', walletBalance);
  const walletEnabled = userHasCreatedWallet && Number(walletBalance) >= listingPrice;
  // const cardEnabled =  Number(walletBalance) >= listingPrice;
  const cardEnabled = true;
  const walletAndCardEnabled =
    Number(walletBalance) < listingPrice &&
    Number(walletBalance) > config.listingMinimumPriceSubUnits;

  const paymentMethodMaybe = walletAndCardEnabled
    ? { paymentMethod: config.PAYMENT_METHODS.CARD_WALLET }
    : walletEnabled
    ? { paymentMethod: config.PAYMENT_METHODS.WALLET }
    : cardEnabled
    ? { paymentMethod: config.PAYMENT_METHODS.CARD }
    : {};

  const initialValues = {
    ...quantityMaybe,
    ...singleDeliveryMethodAvailableMaybe,
    // paymentMethod: 'card',
    // ...paymentMethodMaybe,
  };

  if (!paymentMethodMaybe.paymentMethod) {
    return null;
  }

  return (
    <FinalForm
      initialValues={initialValues}
      hasMultipleDeliveryMethods={hasMultipleDeliveryMethods}
      {...props}
      intl={intl}
      render={renderForm}
      walletEnabled={walletEnabled}
      cardEnabled={cardEnabled}
      walletAndCardEnabled={walletAndCardEnabled}
      walletBalance={walletBalance}
      loading={loading}
    />
  );
};

ProductOrderForm.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  currentStock: null,
  listingId: null,
  isOwnListing: false,
  lineItems: null,
  fetchLineItemsError: null,
};

ProductOrderForm.propTypes = {
  rootClassName: string,
  className: string,

  // form
  formId: string.isRequired,
  onSubmit: func.isRequired,

  // listing
  listingId: propTypes.uuid,
  price: propTypes.money,
  currentStock: number,
  isOwnListing: bool,

  // line items
  lineItems: propTypes.lineItems,
  onFetchTransactionLineItems: func.isRequired,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // other
  onContactUser: func,
};

export default ProductOrderForm;
