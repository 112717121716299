import React from 'react';
import {
  FieldAirbnbDateTimePicker,
  FieldFileUpload,
  FieldSelect,
  FieldTextInput,
  Form,
  PrimaryButton,
  SecondaryButton,
} from '../../components';
import { Form as FinalForm } from 'react-final-form';
import css from './MangopayPayoutPage.module.css';
import { required } from '../../util/validators';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { AiFillMinusCircle } from 'react-icons/ai';
import { FormattedMessage } from 'react-intl';
const ACCEPT_FILES = 'application/pdf,image/png,image/jpg,image/jpeg';

function KycForm(props) {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          handleSubmit,
          errors,
          inProgress,
          setActive,
          mangopayConnectError,
          intl,
        } = formRenderProps;
        const submitDisabled = Object.keys(errors).length > 0 || inProgress;
        const errorsMaybe = mangopayConnectError ? (
          Array.isArray(mangopayConnectError) ? (
            <ul className="pl-4 list-disc mt-0">
              {mangopayConnectError.map(error => (
                <li key={error} className="text-red-500">
                  {error}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-red-500 mt-0">{mangopayConnectError}</p>
          )
        ) : null;

        const kycFileLabel = intl.formatMessage({
          id: 'KycForm.kycFileLabel',
        });
        const kycFileBackLabel = intl.formatMessage({
          id: 'KycForm.kycFileBackLabel',
        });
        const submitButtonLabel = intl.formatMessage({
          id: 'KycForm.submitButtonLabel',
        });

        return (
          <Form onSubmit={handleSubmit}>
            {errorsMaybe}
            <p className={css.text}>
              {intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.identityHeading' })}
            </p>
            <p className={css.subHeading}>
              {intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.identitySubHeading' })}
            </p>
            <p className={css.text}>
              <FormattedMessage
                id="MangopayPayoutPage.KycForm.identityOptionOne"
                values={{
                  link: <b>{intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.moreThan' })}</b>,
                }}
              />
            </p>
            <p className={css.text}>
              <FormattedMessage
                id="MangopayPayoutPage.KycForm.identityOptionTwo"
                values={{
                  link: (
                    <b>{intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.reachAmount' })}</b>
                  ),
                }}
              />
            </p>
            <p className={css.text}>
              <FormattedMessage
                id="MangopayPayoutPage.KycForm.identityStepFinal"
                values={{
                  link: (
                    <b>{intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.warningText' })}</b>
                  ),
                }}
              />
            </p>
            <br />
            <div className={css.grid}>
              <FieldFileUpload
                ACCEPT_FILES={ACCEPT_FILES}
                id="kycFile"
                name="kycFile"
                label={kycFileLabel}
                validate={required(
                  intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.requiredLabel' })
                )}
              />
              <FieldFileUpload
                ACCEPT_FILES={ACCEPT_FILES}
                id="kycFileBack"
                name="kycFileBack"
                label={kycFileBackLabel}
                // validate={required('KYC file is required.')}
              />
            </div>
            <div className={css.information}>
              <h3 className={css.heading}>
                {intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.kycHeading' })}
              </h3>
              <p className={css.subHeading}>
                <b> {intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.kycSubheadingOne' })}:</b>{' '}
                {intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.kycSubheadingTwo' })}
              </p>
              <div className={css.mainContainer}>
                <div className={css.leftContainer}>
                  <p className={css.rejectHeading}>
                    {intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.kycSubHeadingThree' })}
                  </p>
                  <ul>
                    <li className={css.list}>
                      <AiFillMinusCircle className={css.crossMark} />
                      {intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.notUploadScanned' })}
                    </li>
                    <li className={css.list}>
                      <AiFillMinusCircle className={css.crossMark} />
                      {intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.kycSubheadingFour' })}
                    </li>
                    <li className={css.list}>
                      <AiFillMinusCircle className={css.crossMark} />
                      {intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.kycSubheadingFive' })}
                    </li>
                    <li className={css.list}>
                      <AiFillMinusCircle className={css.crossMark} />
                      {intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.kycSubheadingSix' })}
                    </li>
                    <li className={css.list}>
                      <AiFillMinusCircle className={css.crossMark} />
                      {intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.kycSubheadingSeven' })}
                    </li>
                    <li className={css.list}>
                      <AiFillMinusCircle className={css.crossMark} />
                      {intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.kycSubheadingEight' })}
                    </li>
                    <li className={css.list}>
                      <AiFillMinusCircle className={css.crossMark} />
                      {intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.kycSubheadingNine' })}
                    </li>
                    <li className={css.list}>
                      <AiFillMinusCircle className={css.crossMark} />
                      {intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.kycSubheadingTen' })}
                    </li>
                  </ul>
                </div>
                <div className={css.rightContainer}>
                  <p className={css.rejectHeading}>
                    {intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.kycSubheadingEleven' })}{' '}
                  </p>
                  <ul>
                    <li className={css.list}>
                      <BsFillCheckCircleFill className={css.checkMark} />
                      {intl.formatMessage({
                        id: 'MangopayPayoutPage.KycForm.uploadPhotoTakenByPhone',
                      })}
                    </li>
                    <li className={css.list}>
                      <BsFillCheckCircleFill className={css.checkMark} />
                      {intl.formatMessage({ id: 'MangopayPayoutPage.KycForm.kycSubheadingTwelve' })}
                    </li>
                    <li className={css.list}>
                      <BsFillCheckCircleFill className={css.checkMark} />
                      {intl.formatMessage({
                        id: 'MangopayPayoutPage.KycForm.kycSubheadingThirteen',
                      })}
                    </li>
                    <li className={css.list}>
                      <BsFillCheckCircleFill className={css.checkMark} />
                      {intl.formatMessage({
                        id: 'MangopayPayoutPage.KycForm.kycSubheadingFourteen',
                      })}
                    </li>
                    <li className={css.list}>
                      <BsFillCheckCircleFill className={css.checkMark} />
                      {intl.formatMessage({
                        id: 'MangopayPayoutPage.KycForm.kycSubheadingFifteen',
                      })}
                    </li>
                    <li className={css.list}>
                      <BsFillCheckCircleFill className={css.checkMark} />
                      {intl.formatMessage({
                        id: 'MangopayPayoutPage.KycForm.kycSubheadingSixteen',
                      })}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="my-8">
              <PrimaryButton inProgress={inProgress} disabled={submitDisabled}>
                {submitButtonLabel}
              </PrimaryButton>
              {/* <SecondaryButton
                className="mt-4"
                disabled={inProgress}
                onClick={() => setActive(active => active - 1)}
              >
                Prev: Register bank account
              </SecondaryButton> */}
            </div>
          </Form>
        );
      }}
    />
  );
}

export default KycForm;
